<template>
  <div class="legend-group">
    <p class="text-body-sm font-weight-medium text--aeon-navy mb-3 mb-md-4 text-uppercase">{{ $t('vizui.legend') }}</p>

    <v-expansion-panels accordion flat v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header class="mb-2">
          <p class="text-body-sm text--aeon-orange mb-0">{{ $t('vizui.temp') }}</p>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="text--aeon-navy">
          <p class="text-body-xs mb-2">{{ $t('legend.temp.winter') }}</p>
          <div class="bar-gradient-temp-winter mb-2" />
          <div class="bar-gradient-label mb-4">
            <span
              v-for="i in labelGradientTempWinter"
              :key="i + '-TEMP'"
              class="text-body-xs font-weight-light legend-label"
              >{{ i }}</span
            >
          </div>

          <p class="text-body-xs mb-2">{{ $t('legend.temp.annual') }}</p>
          <div class="bar-gradient-temp-annual mb-2" />
          <div class="bar-gradient-label mb-4">
            <span
              v-for="i in labelGradientTempNormal"
              :key="i + '-TEMP'"
              class="text-body-xs font-weight-light legend-label"
              >{{ i }}</span
            >
          </div>

          <p class="text-body-xs mb-2">{{ $t('legend.temp.summer') }}</p>
          <div class="bar-gradient-temp-summer mb-2" />
          <div class="bar-gradient-label mb-4">
            <span
              v-for="i in labelGradientTempSummer"
              :key="i + '-TEMP'"
              class="text-body-xs font-weight-light legend-label"
              >{{ i }}</span
            >
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="mb-2">
          <p class="text-body-sm text--aeon-orange mb-0">{{ $t('vizui.hi') }}</p>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="text--aeon-navy">
          <p class="text-body-xs mb-2">{{ $t('legend.hi.winter') }}</p>
          <div class="bar-gradient-hi-winter mb-2" />
          <div class="bar-gradient-label mb-4">
            <span
              v-for="i in labelGradientHeatIndexWinter"
              :key="i + '=HI'"
              class="text-body-xs font-weight-light legend-label"
              >{{ i }}</span
            >
          </div>

          <p class="text-body-xs mb-2">{{ $t('legend.hi.annual') }}</p>
          <div class="bar-gradient-hi-annual mb-2" />
          <div class="bar-gradient-label mb-4">
            <span
              v-for="i in labelGradientHeatIndexNormal"
              :key="i + '-HI'"
              class="text-body-xs font-weight-light legend-label"
              >{{ i }}</span
            >
          </div>

          <p class="text-body-xs mb-2">{{ $t('legend.hi.summer') }}</p>
          <div class="bar-gradient-hi-summer mb-2" />
          <div class="bar-gradient-label mb-4">
            <span
              v-for="i in labelGradientHeatIndexSummer"
              :key="i + '-HI'"
              class="text-body-xs font-weight-light legend-label"
              >{{ i }}</span
            >
          </div>

          <v-row no-gutters align="center" justify="space-between" class="my-4">
            <v-col cols="3" md="5">
              <img src="@/assets/img/hi-legend.svg" alt="hi legend svg" width="100%" class="mb-2" />
              <img src="@/assets/img/hi-arrow.svg" alt="arrow svg" width="100%" />
            </v-col>
            <v-col cols="8" md="12">
              <span class="text-body-xs font-weight-light legend-label">{{ $t('legend.hi.caption') }}</span>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="mb-2">
          <p class="text-body-sm text--aeon-orange mb-0">{{ $t('vizui.days') }}</p>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="text--aeon-navy">
          <v-row no-gutters align="start" justify="space-between">
            <v-col cols="6" md="12" class="ddays-legend mb-4 mb-md-1">
              <img src="@/assets/img/legend/yellow-outline-accessible.svg" />
              <p class="text-body-xs font-weight-light legend-label mb-0 ml-4">
                {{ $t('legend.days.length') }}
              </p>
            </v-col>

            <v-col cols="6" md="12" class="ddays-legend mb-4 mb-md-1">
              <img src="@/assets/img/legend/yellow-fill-accessible.svg" />
              <p class="text-body-xs font-weight-light legend-label mb-0 ml-4">
                {{ $t('legend.days.height') }}
              </p>
            </v-col>

            <v-col cols="6" md="12" class="ddays-legend mb-4 mb-md-1">
              <img src="@/assets/img/legend/orange-fill-accessible.svg" />
              <p class="text-body-xs font-weight-light legend-label mb-0 ml-4">
                {{ $t('legend.days.orange') }}
              </p>
            </v-col>

            <v-col cols="6" md="12" class="ddays-legend mb-4 mb-md-1">
              <img src="@/assets/img/legend/grey-accessible.svg" />
              <p class="text-body-xs font-weight-light legend-label mb-0 ml-4">
                {{ $t('legend.days.grey') }}
              </p>
            </v-col>

            <v-col cols="6" md="12" class="ddays-legend mb-4 mb-md-1">
              <img src="@/assets/img/legend/black-accessible.svg" />
              <p class="text-body-xs font-weight-light legend-label mb-0 ml-4">
                {{ $t('legend.days.black') }}
              </p>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="mb-2">
          <p class="text-body-sm text--aeon-orange mb-0">{{ $t('vizui.precip') }}</p>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="text--aeon-navy">
          <v-row no-gutters align="center" class="mb-4">
            <v-col cols="3" md="4">
              <p class="text-body-xs font-weight-light mb-0">{{ $t('legend.precip.max') }}</p>
              <span class="circle large" />
            </v-col>

            <v-col cols="8" md="8">
              <p class="text-body-xs font-weight-light legend-label pl-2">
                {{ $t('legend.precip.size') }}
              </p>
            </v-col>
          </v-row>

          <v-row no-gutters align="start" justify="space-between">
            <v-col cols="6" md="12" v-for="i in precipitationLabels" :key="i.label" class="precipitation-circles">
              <div
                class="circle"
                :style="{
                  background: i.color ? i.color : 'none',
                  border: i.color ? 'unset' : '0.5px solid #343768',
                }"
              />
              <p class="text-body-xs font-weight-light legend-label ml-4 mb-0">
                {{ $t(i.label) }}
              </p>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="mb-2">
          <p class="text-body-sm text--aeon-orange mb-0">{{ $t('notes.header') }}</p>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="text--aeon-navy">
          <div v-for="(n, i) in $t('notes.items-climate')" :key="i + 'legend-climate'">
            <p class="text-body-xs mb-0">{{ n.title }}</p>
            <ol class="text-body-xs font-weight-light pl-4 mb-3">
              <li class="mb-2">
                {{ n.content1 }}
              </li>
              <li>
                {{ n.content2 }}
              </li>
            </ol>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- END OF PANELS  -->
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: 'UiLegend',
  data() {
    return {
      panel: 0,
      // temperature range
      labelGradientTempWinter: ['6.2', '12.5', '18.8', '25.1', '31.4'],
      labelGradientTempNormal: ['17.3', '22.4', '27.5', '32.5', '37.6'],
      labelGradientTempSummer: ['14.3', '21.8', '29.2', '36.7', '44.1'],
      // heat index range
      labelGradientHeatIndexWinter: ['5.0', '11.6', '18.0', '24.6', '31.1'],
      labelGradientHeatIndexNormal: ['16.0', '21.6', '27.3', '32.9', '38.5'],
      labelGradientHeatIndexSummer: ['23.4', '29.7', '36.1', '42.4', '48.7'],
      precipitationLabels: [
        {
          color: 'linear-gradient(271.51deg, #B9D7EC 6.09%, #B1D6F0 98.72%)',
          label: '0 - 49.9mm',
        },
        {
          color: 'linear-gradient(270deg, #7AC4F9 0%, #8ECDF1 100%)',
          label: '50 - 99.9mm',
        },
        {
          color: 'linear-gradient(270deg, #4D8AB6 0%, #5EA7DC 100%)',
          label: '100 - 149.9mm',
        },
        {
          color: 'linear-gradient(270deg, #164986 0%, #1D75B5 100%)',
          label: '150 - 199.9mm',
        },
        {
          color: 'linear-gradient(270deg, #082B44 0%, #174565 100%)',
          label: '200mm and above',
        },
        {
          color: false,
          label: 'Outline = years with the lowest and highest precipitation',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
img {
  display: block;
}

.ddays-legend {
  display: flex;
  align-items: center;

  img {
    width: 14px;
    @media #{map-get($display-breakpoints, 'xl-only')} {
      width: 15px;
    }
  }

  p {
    flex: 1 1 auto;
  }
}

.legend-group {
  p.legend-label {
    vertical-align: middle;
  }

  .legend-precipitation {
    width: 15px;
    height: 15px;
    display: block;
    margin-right: 8px;
    border-radius: 50%;
    background: linear-gradient(180deg, #749dbb 0%, #d3e2ec 100%);
  }

  .bar-gradient-temp-winter {
    background: linear-gradient(
      270deg,
      #1386ab 0%,
      #2099c0 5.73%,
      #2ca6cd 13.54%,
      #42b9df 23.44%,
      #57c6e9 30.73%,
      #57cee9 38.54%,
      #6fd6ed 46.35%,
      #85dfe5 53.65%,
      #baebee 60.42%,
      #ffeac2 67.71%,
      #ffe3c2 74.48%,
      #ffdcc2 82.29%,
      #fdd6b3 89.58%,
      #fdcc9f 95.83%,
      #ffbf85 100%
    );
    transform: rotate(-180deg);
    width: 100%;
    height: 20px;
  }

  .bar-gradient-temp-annual {
    background: linear-gradient(
      270deg,
      #baebee 0%,
      #ffeac2 10.42%,
      #ffe3c2 21.87%,
      #ffdcc2 31.25%,
      #fdd6b3 40.1%,
      #fdcc9f 51.04%,
      #ffbf85 58.33%,
      #ffb067 70.31%,
      #fea14c 80.21%,
      #fc8f2b 90.1%,
      #fc8f2b 100%
    );
    transform: rotate(-180deg);
    width: 100%;
    height: 20px;
  }

  .bar-gradient-temp-summer {
    background: linear-gradient(
      270deg,
      #fc8f2b 0%,
      #f87328 7.81%,
      #f3511e 17.71%,
      #e33921 27.08%,
      #cd1717 39.06%,
      #a20c0c 48.96%,
      #850808 61.46%,
      #600101 76.56%,
      #3d0606 90.62%,
      #160101 100%
    );
    transform: rotate(-180deg);
    width: 100%;
    height: 20px;
  }

  .bar-gradient-hi-winter {
    background: linear-gradient(
      270deg,
      #ededed 0%,
      #e0e2f0 6.25%,
      #d1d5f3 12.5%,
      #c6cbf5 18.75%,
      #b7bdf1 25%,
      #a6adec 31.25%,
      #a6baec 37.5%,
      #a7cee4 43.75%,
      #a8e4cb 50%,
      #b8eca6 56.25%,
      #dcee70 62.5%,
      #f6f178 68.75%,
      #fae84a 75%,
      #ffd979 81.25%,
      #ffc979 87.5%,
      #ffb179 93.75%,
      #ffa179 100%
    );
    transform: rotate(-180deg);
    width: 100%;
    height: 20px;
  }

  .bar-gradient-hi-annual {
    background: linear-gradient(
      270deg,
      #a7cee4 0%,
      #a8e4cb 7.14%,
      #b8eca6 14.29%,
      #dcee70 21.43%,
      #f6f178 28.57%,
      #fae84a 35.71%,
      #ffd979 42.86%,
      #ffc979 50%,
      #ffb179 57.14%,
      #ffa179 64.29%,
      #fb9170 71.43%,
      #f9882d 78.57%,
      #f2722a 85.71%,
      #f25a2a 92.86%,
      #da3922 100%
    );
    transform: rotate(-180deg);
    width: 100%;
    height: 20px;
  }

  .bar-gradient-hi-summer {
    background: linear-gradient(
      270deg,
      #f6f178 0%,
      #fae84a 6.25%,
      #ffd979 12.5%,
      #ffc979 18.75%,
      #ffb179 25%,
      #ffa179 31.25%,
      #fb9170 37.5%,
      #f9882d 43.75%,
      #f2722a 50%,
      #f25a2a 56.25%,
      #da3922 62.5%,
      #c21717 68.75%,
      #9e103b 75%,
      #861d68 81.25%,
      #6f0764 87.5%,
      #54054d 93.75%,
      #41033b 100%
    );
    transform: rotate(-180deg);
    width: 100%;
    height: 20px;
  }

  .bar-gradient-label {
    display: flex;
    justify-content: space-between;
  }

  .bar-gradient-label span {
    position: relative;
  }

  .bar-gradient-label span::before {
    content: '';
    position: absolute;
    width: 0.5px;
    height: 8px;
    background-color: #343768;
    left: 10px;
    top: -9px;
    z-index: -1;
  }

  .bar-gradient-label span:first-child::before {
    content: '';
    position: absolute;
    width: 0.5px;
    height: 8px;
    background-color: #343768;
    left: 0px;
    top: -9px;
    z-index: -1;
  }

  .bar-gradient-label span:last-child::before {
    content: '';
    position: absolute;
    width: 0.5px;
    height: 8px;
    background-color: #343768;
    left: 15px;
    top: -9px;
    z-index: -1;
  }
}

/* vertical arrows */
.line-vertical {
  height: 75px; /* line length */
  position: relative;
  background: none;
  border: 0.5px dashed red;
  display: inline-block;
}

.arrow-up:after,
.arrow-down:before {
  content: '';
  position: absolute;
  width: 0px;
  height: 0px;
  border: 6px solid transparent;
  left: -6px;
}

.arrow-up:after {
  top: -10px;
  border-bottom: 8px solid #aaa;
}
.arrow-down:before {
  bottom: -10px;
  border-top: 8px solid #aaa;
}

.precipitation-circles {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;

  div.circle {
    width: 16px !important;
    height: 16px;
    border-radius: 50%;
    display: inline-block;

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      width: 18px !important;
      height: 18px;
    }
  }

  p {
    flex: 1 1 auto;
    max-width: 80%;
  }
}

.circle.large {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  border: 0.5px solid #343768;
}

.v-input--selection-controls {
  padding-top: 0px !important;
  flex: 1 1 auto !important;
}

.v-expansion-panel {
  background-color: transparent !important;

  .v-expansion-panel-content {
    ::v-deep .v-expansion-panel-content__wrap {
      padding: 0 !important;
    }
  }

  button.v-expansion-panel-header {
    padding: 0 !important;
    min-height: 0 !important;
    ::v-deep .v-icon {
      color: #e27259 !important;
    }
  }
  button.v-expansion-panel-header--active {
    min-height: 0 !important;
  }
}
</style>
