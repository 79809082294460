<template>
  <section class="page-climate--viz">
    <v-container fluid class="px-5 px-md-9 px-lg-16">
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <p class="text-body-sm font-weight-medium text--aeon-navy input-label text-uppercase my-2">
            {{ $t('vizui.view') }}
          </p>
          <div class="d-flex flex-row flex-wrap align-start">
            <UiDropdownBtn
              :itemList1="cityList1"
              :itemList2="cityList2"
              :itemList3="cityList3"
              :itemLabel="['btn.location_label.label1', 'btn.location_label.label2', 'btn.location_label.label3']"
              :menuLabel="$t('vizui.loc')"
              :alignProp="true"
              @update-data="handleView"
            />

            <UiDropdownBtn
              :itemList1="seasonList1"
              :menuLabel="$t('vizui.season')"
              :alignProp="true"
              @update-data="handleSeasonChange"
            />
          </div>
          <UiAccColorToggle @update-color="handleColorChange" class="hidden-xs-only" />
        </v-col>

        <v-col cols="12" sm="6">
          <p class="text-body-sm font-weight-medium text--aeon-navy input-label text-uppercase my-2">
            {{ $t('vizui.filter') }}
          </p>

          <div class="d-flex flex-row flex-wrap align-start">
            <UiDropdownBtn
              :itemList1="temperatureList1"
              :itemList2="temperatureList2"
              :itemList3="temperatureList3"
              :menuLabel="$t('vizui.temp')"
              @update-data="handleTempChange"
            />

            <UiDropdownBtn
              :itemList1="heatIndexList1"
              :itemList2="heatIndexList2"
              :itemList3="heatIndexList3"
              :menuLabel="$t('vizui.hi')"
              @update-data="handleHeatIndexChange"
            />
          </div>

          <div class="d-flex flex-row flex-wrap align-start">
            <UiDropdownBtn :itemList1="hotDaysList" :menuLabel="$t('vizui.days')" @update-data="handleDDaysChange" />

            <UiDropdownBtn
              :itemList1="precipitationList"
              :menuLabel="$t('vizui.precip')"
              @update-data="handlePrecipitationChange"
            />
          </div>
        </v-col>
      </v-row>

      <UiAccColorToggle @update-color="handleColorChange" class="hidden-sm-only" />

      <div class="sticky-bar d-flex flex-row align-center justify-space-between py-2 my-4">
        <div>
          <p class="text-body-sm font-weight-medium mr-3 mt-1 text--aeon-navy text-uppercase">
            {{ $t('vizui.current-view') }} :
            <br class="hidden-sm-only" />            
            <span class="text--aeon-orange">{{ selectedViewSanitised }}, {{ selectedSeasonSanitised }}</span>
          </p>        
        </div>
        <div @click="mobileLegend = !mobileLegend">
          <span class="text-body-sm font-weight-medium text-uppercase text--aeon-navy">{{ $t('vizui.legend') }}</span>
          <v-icon color="#E27259" style="font-size: 20px" class="ml-1">mdi-help-circle</v-icon>
        </div>
      </div>

      <v-row justify="center" align="start" v-if="selectedView === 'gcc-overall'">
        <v-col cols="12" sm="10" md="6" class="pa-md-4 pa-xl-12 viz-wrapper" v-for="n in gcc_cities" :key="n.id">
          <VizWeatherCircleAcc
            :precipitation="selectedPrecipitation"
            :temperature="selectedTemperature"
            :ddays="selectedDDays"
            :heatindex="selectedHeatIndex"
            :city="n.id"
            :trigger="isIntersecting"
            v-if="isAccessible"
          />

          <VizWeatherCircle
            :precipitation="selectedPrecipitation"
            :temperature="selectedTemperature"
            :ddays="selectedDDays"
            :heatindex="selectedHeatIndex"
            :city="n.id"
            :trigger="isIntersecting"
            v-else
          />
        </v-col>
      </v-row>

      <v-row justify="center" align="start" v-else-if="selectedView === 'ksa-overall'">
        <v-col cols="12" sm="10" md="6" class="mb-8 pa-md-4 pa-xl-12" v-for="n in ksa_cities" :key="n.id">
          <VizWeatherCircleAcc
            :precipitation="selectedPrecipitation"
            :temperature="selectedTemperature"
            :ddays="selectedDDays"
            :heatindex="selectedHeatIndex"
            :city="n.id"
            :trigger="isIntersecting"
            v-if="isAccessible"
          />

          <VizWeatherCircle
            :precipitation="selectedPrecipitation"
            :temperature="selectedTemperature"
            :ddays="selectedDDays"
            :heatindex="selectedHeatIndex"
            :city="n.id"
            :trigger="isIntersecting"
            v-else
          />
        </v-col>
      </v-row>

      <v-row
        justify="center"
        align="start"
        v-else-if="selectedView !== 'ksa-overall' && selectedView !== 'gcc-overall'"
      >
        <v-col cols="12" sm="10" md="8" class="pa-md-4 pa-xl-12" v-for="n in cityArray" :key="n + 'solo-viz'">
          <VizWeatherCircleAcc
            :precipitation="selectedPrecipitation"
            :temperature="selectedTemperature"
            :ddays="selectedDDays"
            :heatindex="selectedHeatIndex"
            :city="n"
            :trigger="isIntersecting"
            v-if="isAccessible"
          />

          <VizWeatherCircle
            :precipitation="selectedPrecipitation"
            :temperature="selectedTemperature"
            :ddays="selectedDDays"
            :heatindex="selectedHeatIndex"
            :city="n"
            :trigger="isIntersecting"
            v-else
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      hide-overlay
      :key="'mobile-legend'"
      :elevation="0"
      v-model="mobileLegend"
      transition="dialog-bottom-transition"
      max-width="400px"
    >
      <div class="pa-3" style="background: rgba(250, 250, 250, 0.95)">
        <UiLegendClimateAcc v-if="isAccessible" />
        <UiLegendClimate v-else />
      </div>
    </v-dialog>
  </section>
</template>

<script>
import UiLegendClimate from '@/components/ui/UiLegendClimate';
import UiLegendClimateAcc from '@/components/ui/UiLegendClimateAcc';
import UiDropdownBtn from '@/components/ui/UiDropdownBtn';
import UiAccColorToggle from '@/components/ui/UiAccColorToggle';

import VizWeatherCircle from '@/components/viz/VizWeatherCircle';
import VizWeatherCircleAcc from '@/components/viz/VizWeatherCircleAcc';

export default {
  name: 'SectionClimateViz',
  components: {
    UiLegendClimate,
    UiLegendClimateAcc,
    UiDropdownBtn,
    UiAccColorToggle,
    VizWeatherCircle,
    VizWeatherCircleAcc,
  },
  computed: {
    selectedViewSanitised() {
      const cityCluster = {
        'ksa-overall': 'btn.location.ksa_all',
        ksa: 'btn.location.ksa',
        'gcc-overall': 'btn.location.gcc_all',
        gcc: 'btn.location.gcc',
        'abu-dhabi': 'btn.location.abu-dhabi',
        dammam: 'btn.location.dammam',
        doha: 'btn.location.doha',
        dubai: 'btn.location.dubai',
        jeddah: 'btn.location.jeddah',
        'kuwait-city': 'btn.location.kuwait-city',
        makkah: 'btn.location.makkah',
        manama: 'btn.location.manama',
        muscat: 'btn.location.muscat',
        riyadh: 'btn.location.riyadh',
      };

      return this.$t(cityCluster[this.selectedView]);
    },
    selectedSeasonSanitised() {
      const seasonLabel = this.seasonList1.find(d => d.id === this.selectedSeason);
      return this.$t(seasonLabel.label);
    },
    cityArray() {
      const city = [];
      city.push(this.selectedView);
      return city;
    },
  },
  data() {
    return {
      // drop down list options
      temperatureList1: [
        { label: 'btn.temp.annual', id: 'annual_temp' },
        { label: 'btn.temp.annual_min', id: 'min_temp' },
        { label: 'btn.temp.annual_max', id: 'max_temp' },
      ],
      temperatureList2: [
        { label: 'btn.temp.summer', id: 'summer_temp' },
        { label: 'btn.temp.summer_min', id: 'summer_min_temp' },
        { label: 'btn.temp.summer_max', id: 'summer_max_temp' },
      ],
      temperatureList3: [
        { label: 'btn.temp.winter', id: 'winter_temp' },
        { label: 'btn.temp.winter_min', id: 'winter_min_temp' },
        { label: 'btn.temp.winter_max', id: 'winter_max_temp' },
      ],
      heatIndexList1: [
        { label: 'btn.hi.annual', id: 'annual_hi' },
        { label: 'btn.hi.annual_min', id: 'min_hi' },
        { label: 'btn.hi.annual_max', id: 'max_hi' },
      ],
      heatIndexList2: [
        { label: 'btn.hi.summer', id: 'summer_hi' },
        { label: 'btn.hi.summer_min', id: 'summer_min_hi' },
        { label: 'btn.hi.summer_max', id: 'summer_max_hi' },
      ],
      heatIndexList3: [
        { label: 'btn.hi.winter', id: 'winter_hi' },
        { label: 'btn.hi.winter_min', id: 'winter_min_hi' },
        { label: 'btn.hi.winter_max', id: 'winter_max_hi' },
      ],
      hotDaysList: [
        { label: 'btn.days.dangerous_days', id: 'dangerous_days' },
        { label: 'btn.days.100f_days', id: 'days_100f' },
      ],
      precipitationList: [
        { label: 'btn.precip.annual', id: 'annual_prep' },
        { label: 'btn.precip.summer', id: 'summer_prep' },
        { label: 'btn.precip.winter', id: 'winter_prep' },
      ],
      cityList1: [
        { label: 'btn.location.ksa', id: 'ksa' },
        { label: 'btn.location.gcc', id: 'gcc' },
      ],
      cityList2: [
        { label: 'btn.location.ksa_all', id: 'ksa-overall' },
        { label: 'btn.location.gcc_all', id: 'gcc-overall' },
      ],
      cityList3: [
        { label: 'btn.location.abu-dhabi', id: 'abu-dhabi' },
        { label: 'btn.location.dammam', id: 'dammam' },
        { label: 'btn.location.doha', id: 'doha' },
        { label: 'btn.location.dubai', id: 'dubai' },
        { label: 'btn.location.jeddah', id: 'jeddah' },
        { label: 'btn.location.kuwait-city', id: 'kuwait-city' },
        { label: 'btn.location.makkah', id: 'makkah' },
        { label: 'btn.location.manama', id: 'manama' },
        { label: 'btn.location.muscat', id: 'muscat' },
        { label: 'btn.location.riyadh', id: 'riyadh' },
      ],
      seasonList1: [
        { label: 'btn.season.annual', id: 'annual' },
        { label: 'btn.season.summer', id: 'summer' },
        { label: 'btn.season.winter', id: 'winter' },
      ],
      // rendering the viz grid by cities
      ksa_cities: [
        { label: 'btn.location.dammam', id: 'dammam' },
        { label: 'btn.location.jeddah', id: 'jeddah' },
        { label: 'btn.location.makkah', id: 'makkah' },
        { label: 'btn.location.riyadh', id: 'riyadh' },
      ],
      gcc_cities: [
        { label: 'btn.location.abu-dhabi', id: 'abu-dhabi' },
        { label: 'btn.location.doha', id: 'doha' },
        { label: 'btn.location.dubai', id: 'dubai' },
        { label: 'btn.location.kuwait-city', id: 'kuwait-city' },
        { label: 'btn.location.manama', id: 'manama' },
        { label: 'btn.location.muscat', id: 'muscat' },
        { label: 'btn.location.riyadh', id: 'riyadh' },
      ],
      // default selection variables
      selectedPrecipitation: 'annual_prep',
      selectedTemperature: 'annual_temp',
      selectedDDays: 'dangerous_days',
      selectedHeatIndex: 'annual_hi',
      selectedSeason: 'annual',
      selectedView: 'gcc-overall', //this is the default view
      mobileLegend: false,
      isIntersecting: false,
      // accessibility
      isAccessible: false,
    };
  },
  methods: {
    handlePrecipitationChange(event) {
      this.selectedPrecipitation = event.id;
    },
    handleTempChange(event) {
      this.selectedTemperature = event.id;
    },
    handleDDaysChange(event) {
      this.selectedDDays = event.id;
    },
    handleHeatIndexChange(event) {
      this.selectedHeatIndex = event.id;
    },
    handleView(event) {
      this.selectedView = event.id;
    },
    handleSeasonChange(event) {
      const seasonVariables = {
        annual: ['annual_temp', 'annual_hi', 'annual_prep'],
        summer: ['summer_temp', 'summer_hi', 'summer_prep'],
        winter: ['winter_temp', 'winter_hi', 'winter_prep'],
      };

      this.selectedSeason = event.id;

      this.selectedTemperature = seasonVariables[event.id][0];
      this.selectedHeatIndex = seasonVariables[event.id][1];
      this.selectedPrecipitation = seasonVariables[event.id][2];
    },
    handleIntersectViz(entries) {
      this.isIntersecting = entries[0].isIntersecting;
    },
    handleColorChange(event) {
      this.isAccessible = event;
    },
  },
};
</script>

<style scoped lang="scss">
.page-climate--viz {
  background: #f5f9fe;

  .sticky-bar {
    position: -webkit-sticky;
    position: sticky;
    top: 67px;
    padding-top: 12px;
    padding-bottom: 56px;
    padding-left: 0px;
    padding-right: 0px;
    background-color: #f5f9fe;
    z-index: 2;
    height: 48px;
    overflow: hidden;
    justify-content: space-between;
  }
}
</style>
