<template>
  <v-switch @change="$emit('update-color', $event)" flat inset dense :label="$t('vizui.colors')" color="#E27259" />
</template>

<script>
export default {
  name: 'UiAccColorToggle',
  data() {
    return {
      accessible: true,
    };
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-label.theme--light {
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  color: #343768;
}
</style>
