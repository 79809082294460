<template>
  <div class="page-climate">
    <div class="page-climate--landing pb-16">
      <UiNavbar />
      <SectionLanding />
    </div>

    <SectionClimateViz v-if="$vuetify.breakpoint.mdAndUp" />
    <SectionClimateVizMobile v-else />

    <SectionMethods />
    <SectionFooter />
  </div>
</template>

<script>
import SectionLanding from '@/components/page-climate/SectionLanding';
import SectionClimateViz from '@/components/page-climate/SectionClimateViz';
import SectionClimateVizMobile from '@/components/page-climate/SectionClimateVizMobile';
import SectionMethods from '@/components/SectionMethods';
import SectionFooter from '@/components/SectionFooter';
import UiNavbar from '@/components/ui/UiNavbar.vue';

export default {
  name: 'AeonClimateViz',
  components: {
    SectionLanding,
    SectionClimateViz,
    SectionClimateVizMobile,
    SectionMethods,
    SectionFooter,
    UiNavbar,
  },
};
</script>

<style scoped lang="scss">
.page-climate {
  position: relative;

  &--landing {
    background: linear-gradient(180deg, #aad4e7 0%, rgba(170, 212, 231, 0.7) 0.01%, #f6faff 100%);
  }
}
</style>
