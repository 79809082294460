<template>
  <div class="chart-radial-aeon">
    <div :id="`chart-wrapper-${city}`">
      <svg :id="`radial-aeon-${city}`" />
    </div>

    <div :class="[city, 'tooltip', 'text-body-xs', 'dirchange']">
      <span class="text-body-xs">{{ $t('tooltip.year') }} : {{ selectedItem.year }}</span> <br />
      <span v-if="showData === 'precipitation'">{{ $t('tooltip.precip') }} : {{ selectedItem[precipitation] }} </span>
      <span v-if="showData === 'temperature'">{{ $t('tooltip.temp') }} : {{ selectedItem[temperature] }}</span>
      <span v-if="showData === 'ddays'">{{ $t('tooltip.days') }} : {{ selectedItem[ddays] }}</span>
      <span v-if="showData === 'heatindex'">{{ $t('tooltip.hi') }} : {{ selectedItem[heatindex] }}</span>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import json from '@/assets/arabic-labels/paths.json'

export default {
  name: 'VizWeatherCircleAcc',
  props: {
    precipitation: {
      type: String,
      required: true,
    },
    temperature: {
      type: String,
      required: true,
    },
    ddays: {
      type: String,
      required: true,
    },
    heatindex: {
      type: String,
      required: true,
    },
    city: {
      type: String,
      required: true,
    },
    trigger: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    chartDim() {
      return {
        width: 800,
        height: 800,
        margin: 64,
        innerRadius: 100,
        outerRadius: 380,
      };
    },
    hiLinesYScale() {
      // watch for which heat index variable
      const season =
        this.heatindex.split('_')[0] !== 'winter' && this.heatindex.split('_')[0] !== 'summer'
          ? 'annual'
          : this.heatindex.split('_')[0];

      const heatIndexRange = {
        summer: [20, 49],
        annual: [15, 35],
        winter: [0, 30],
      };
      // Summer: 23 - 49 (5 stops, roughly 5.2 deg difference at each stop)
      // Normal: 16 - 39 (5 stops, roughly 4.6 deg difference at each stop)
      // Winter: 5 - 32 (5 stops, roughly 5.4 deg difference at each stop)

      const hiLinesYScale = d3
        .scaleLinear()
        .domain(heatIndexRange[season])
        .range([this.chartDim.outerRadius * 0.76 - 70, this.chartDim.outerRadius * 0.76]);
      // .nice()

      return hiLinesYScale;
    },
    locationLabel() {
      const city = this.city === 'ksa' ? 'ksa--shorten' : this.city
      return this.$t('btn.location.' + city);
    },           
  },
  watch: {
    precipitation(newVal) {
      this.updatePrecipitation(newVal);
    },
    temperature(newVal) {
      this.updateTemperature(newVal);
    },
    ddays(newVal) {
      this.updateDDays(newVal);
    },
    heatindex(newVal) {
      this.updateHeatIndex(newVal);
    },
    trigger(newVal) {
      if (!newVal) {
        this.updateHeatIndex(this.heatindex);
        this.updatePrecipitation(this.precipitation);
      }
    },
    '$i18n.locale': function() {
      this.svg.selectAll('text.city-label').text(this.locationLabel);
      this.updatePrecipitation(this.precipitation);
      this.updateTemperature(this.temperature);
      this.updateDDays(this.ddays);
      this.updateHeatIndex(this.heatindex);      
    }      
  },
  data() {
    return {
      labelsAr: json,
      selectedItem: {
        year: '2018',
        annual_temp: '27.3',
        summer_temp: '36.6',
        max_temp: '42.7',
        min_temp: '29.4',
        annual_hi: '25.4',
        summer_hi: '33.2',
        max_hi: '38.0',
        dangerous_days: '12',
        days_100f: '142',
        annual_prep: '60.9',
        summer_prep: '0.0',
      },
      showData: '',
    };
  },
  async mounted() {
    const rawData = await d3.csv(`${process.env.BASE_URL}data/${this.city}.csv`);
    this.data = rawData.sort((a, b) => parseFloat(a.year) - parseFloat(b.year));
    this.data = this.data.filter(d => +d.year > 1978);

    d3.select(`#radial-aeon-${this.city}`).selectAll('g').remove();

    d3.select(`#chart-wrapper-${this.city}`)
      .select(`#radial-aeon-${this.city}`)
      .attr('width', this.chartDim.width)
      .attr('height', this.chartDim.height)
      .call(this.responsivefy);

    this.svg = d3
      .select(`#radial-aeon-${this.city}`)
      .append('g')
      .attr('class', 'radial-aeon-wrapper')
      .attr('transform', `translate(${this.chartDim.width / 2}, ${this.chartDim.height / 2})`);

    this.initScales();
    this.initLinearGradient();

    this.drawPrecipitationCircles();
    this.drawTemperatureBand();
    this.drawHeatIndexLines();
    this.drawDangerousDaysFlames();
    this.addCityLabel();

    this.drawArabicLabels();  

    this.updateHeatIndex(this.heatindex);
    this.updatePrecipitation(this.precipitation);
    this.updateTemperature(this.temperature);
    this.updateDDays(this.ddays);
  },
  methods: {
    addCityLabel() {
      this.svg
        .append('g')
        .attr('class', 'city-label')
        .attr('transform', 'translate(0, 0)')
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('class', 'city-label')
        .text(this.locationLabel);
    },
    updatePrecipitation(newVal) {
      const chart = d3.select(`#radial-aeon-${this.city}`);

      // update labels
      const precipitationList = {
        annual_prep: 'btn.precip.annual',
        summer_prep: 'btn.precip.summer',
        winter_prep: 'btn.precip.winter',
      };
      const label = precipitationList[newVal];
      chart
        .selectAll('textPath.precipitationlabel')
        .attr('class', 'precipitationlabel')
        .text(this.$t(label));      

      if (this.$i18n.locale === "ar") {
        chart.select('text.precipitationlabel--text').attr('opacity', 0);
        chart.select('g.precipitation-ar').selectAll('path').attr('opacity', 0);
        chart.selectAll(`path[data-id="${label}"]`).attr('opacity', 1);
      } else {
        chart.select('text.precipitationlabel--text').attr('opacity', 1);
        chart.select('g.precipitation-ar').selectAll('path').attr('opacity', 0);
        chart.selectAll(`path[data-id="${label}"]`).attr('opacity', 0);
      }

      // get max and min prep
      const precipMin = d3.min(this.data, function (d) {
        return +d[newVal] || Infinity;
      });
      const precipMax = d3.max(this.data, (d) => +d[newVal]);

      // update chart
      chart
        .selectAll('circle.precipitation')
        .attr('fill', (d) => {
          if (+d[newVal] < 50) {
            // return 'black'
            return 'url(#precip-range-1)';
          } else if (+d[newVal] < 100) {
            return 'url(#precip-range-2)';
          } else if (+d[newVal] < 150) {
            return 'url(#precip-range-3)';
          } else if (+d[newVal] < 200) {
            return 'url(#precip-range-4)';
          } else if (+d[newVal] >= 200) {
            return 'url(#precip-range-5)';
          } else {
            return '#343768';
          }
        })
        .attr('r', 0)
        .transition()
        .duration(1500)
        .attr('r', (d) => this.rScale(d[newVal]));

      chart
        .selectAll('circle.precipitation-outline')
        .attr('r', 0)
        .transition()
        .duration(0)
        .attr('r', (d) => this.rScale(+d[newVal] + 80))
        .attr('stroke', (d) => (+d[newVal] === +precipMax || +d[newVal] === +precipMin ? '#343768' : '#f5f9fe'));
    },
    updateTemperature(newVal) {
      const chart = d3.select(`#radial-aeon-${this.city}`);

      // update labels
      const temperatureList = {
        annual_temp: 'btn.temp.annual',
        min_temp: 'btn.temp.annual_min',
        max_temp: 'btn.temp.annual_max',
        summer_temp: 'btn.temp.summer',
        summer_min_temp: 'btn.temp.summer_min',
        summer_max_temp: 'btn.temp.summer_max',
        winter_temp: 'btn.temp.winter',
        winter_min_temp: 'btn.temp.winter_min',
        winter_max_temp: 'btn.temp.winter_max',
      };      
      const label = temperatureList[newVal];
      chart
        .selectAll('textPath.temperaturelabel')
        .attr('class', 'temperaturelabel')
        .text(this.$t(label));      

      if (this.$i18n.locale === "ar") {
        chart.select('text.temperaturelabel--text').attr('opacity', 0);
        chart.select('g.temperature-ar').selectAll('path').attr('opacity', 0);
        chart.selectAll(`path[data-id="${label}"]`).attr('opacity', 1);
      } else {
        chart.select('text.temperaturelabel--text').attr('opacity', 1);
        chart.select('g.temperature-ar').selectAll('path').attr('opacity', 0);
        chart.selectAll(`path[data-id="${label}"]`).attr('opacity', 0);
      }

      // update chart
      chart
        .selectAll('path.temperature')
        .attr('opacity', 0)
        .attr('fill', (d) => this.tempScale(+d[newVal]))
        .transition()
        .duration(800)
        .attr('opacity', 1);
    },
    updateDDays(newVal) {
      const chart = d3.select(`#radial-aeon-${this.city}`);
      // update labels
      const ddaysList = {
        dangerous_days: 'btn.days.dangerous_days',
        days_100f: 'btn.days.100f_days',
      };

      const label = ddaysList[newVal];
      chart
        .selectAll('textPath.ddayslabel')
        .attr('class', 'ddayslabel')
        .text(this.$t(label));      

      if (this.$i18n.locale === "ar") {
        chart.select('text.ddayslabel--text').attr('opacity', 0);
        chart.select('g.days-ar').selectAll('path').attr('opacity', 0);
        chart.selectAll(`path[data-id="${label}"]`).attr('opacity', 1);
      } else {
        chart.select('text.ddayslabel--text').attr('opacity', 1);
        chart.select('g.days-ar').selectAll('path').attr('opacity', 0);
        chart.selectAll(`path[data-id="${label}"]`).attr('opacity', 0);
      }            

      // update chart
      const ddaysMax = d3.max(this.data, (d) => +d.dangerous_days);

      chart
        .selectAll('path.ddays-flame')
        .attr(
          'stroke',
          (d) =>
            `${
              +d[newVal] === +ddaysMax
                ? '#343768'
                : +d[newVal] > 100
                ? '#C87944'
                : +d[newVal] === 0
                ? '#D0D0D0'
                : '#DEC363'
            }`
        )
        .attr(
          'fill',
          (d) =>
            `${
              newVal === 'dangerous_days'
                ? `url(#${this.city}-ddays-${d.year})`
                : `url(#${this.city}-ddays-100f-${d.year})`
            }`
        );
    },
    updateHeatIndex(newVal) {
      const chart = d3.select(`#radial-aeon-${this.city}`);

      // update labels
      const heatindexList = {
        annual_hi: 'btn.hi.annual',
        min_hi: 'btn.hi.annual_min',
        max_hi: 'btn.hi.annual_max',
        summer_hi: 'btn.hi.summer',
        summer_min_hi: 'btn.hi.summer_min',
        summer_max_hi: 'btn.hi.summer_max',
        winter_hi: 'btn.hi.winter',
        winter_min_hi: 'btn.hi.winter_min',
        winter_max_hi: 'btn.hi.winter_max',
      };
      const label = heatindexList[newVal];
      chart
        .selectAll('textPath.heatindexlabel')
        .attr('class', 'heatindexlabel')
        .text(this.$t(label));      

      if (this.$i18n.locale === "ar") {
        chart.select('text.heatindexlabel--text').attr('opacity', 0);
        chart.select('g.heatindex-ar').selectAll('path').attr('opacity', 0);
        chart.selectAll(`path[data-id="${label}"]`).attr('opacity', 1);
      } else {
        chart.select('text.heatindexlabel--text').attr('opacity', 1);
        chart.select('g.heatindex-ar').selectAll('path').attr('opacity', 0);
        chart.selectAll(`path[data-id="${label}"]`).attr('opacity', 0);
      }        

      // change scale of heat index
      const hiLines = chart.select('g.data-heatindex-lines');
      chart.select('g.hi-yAxis').selectAll('g').remove();

      hiLines.select('g.hi-yAxis').call((g) =>
        g
          .attr('text-anchor', 'middle')
          .attr('font-family', 'sans-serif')
          .call((g) =>
            g
              .selectAll('g')
              .data(this.hiLinesYScale.ticks(3).reverse())
              .join('g')
              .attr('fill', 'none')
              .call((g) =>
                g
                  .append('circle')
                  .attr('stroke', '#343768')
                  .attr('stroke-opacity', '0.25')
                  .attr('stroke-width', 0.5)
                  .attr('stroke-dasharray', 4)
                  .attr('r', this.hiLinesYScale)
              )
              .call((g) =>
                g
                  .append('text')
                  .attr('y', (d) => -this.hiLinesYScale(d))
                  .attr('dy', '0.35em')
                  .attr('stroke', '#f5f9fe')
                  .attr('stroke-width', 1)
                  .text((x, i) => `${x.toFixed(0)}${i ? '°C' : '°C'}`)
                  .clone(true)
                  .attr('y', (d) => this.hiLinesYScale(d))
                  .selectAll(function () {
                    return [this, this.previousSibling];
                  })
                  .clone(true)
                  .attr('fill', 'currentColor')
                  .attr('stroke', 'none')
              )
          )
      );

      hiLines
        .selectAll('g.hi-line')
        .selectAll('line')
        .attr('stroke', (d) => this.hiScale(d[newVal]))
        .attr('x2', (d) => this.hiLinesYScale(d[newVal] - 10))
        .transition()
        .duration(1000)
        .attr('x2', (d) => this.hiLinesYScale(d[newVal]));
    },
    initLinearGradient() {
      const defGroup = this.svg.append('g').attr('class', 'linearGradient');

      const ddaysGradient = defGroup.append('defs').attr('class', 'ddays-gradient');

      ddaysGradient
        .selectAll('linearGradient')
        .data(this.data)
        .join('linearGradient')
        .attr('id', (d) => `${this.city}-ddays-${d.year}`)
        .attr('x1', '0%')
        .attr('x2', '0%')
        .attr('y1', '0%')
        .attr('y2', '100%')
        .call((linearGradient) =>
          linearGradient
            .append('stop')
            .attr('offset', (d) => `${1 - d.dangerous_days / 365}`)
            .style('stop-color', '#f5f9fe')
            .style('stop-opacity', '0.1')
        )
        .call((linearGradient) =>
          linearGradient
            .append('stop')
            .attr('offset', (d) => `${d.dangerous_days / 365}`)
            .style('stop-color', (d) => `${+d.dangerous_days > 100 ? '#C87944' : '#DEC363'}`)
        );

      const ddays100fGradient = defGroup.append('defs').attr('class', 'ddays-100f-gradient');

      ddays100fGradient
        .selectAll('linearGradient')
        .data(this.data)
        .join('linearGradient')
        .attr('id', (d) => `${this.city}-ddays-100f-${d.year}`)
        .attr('x1', '0%')
        .attr('x2', '0%')
        .attr('y1', '0%')
        .attr('y2', '100%')
        .call((linearGradient) =>
          linearGradient
            .append('stop')
            .attr('offset', (d) => `${1 - d.days_100f / 365}`)
            .style('stop-color', '#f5f9fe')
            .style('stop-opacity', '0.1')
        )
        .call((linearGradient) =>
          linearGradient
            .append('stop')
            .attr('offset', (d) => `${d.days_100f / 365}`)
            .style('stop-color', (d) => `${+d.days_100f > 100 ? '#C87944' : '#DEC363'}`)
        );

      // circle linear gradient
      const precipColor = [
        { id: '1', color1: '#B1D6F0', color2: '#B9D7EC', lowerLimit: 0, upperLimit: 50 },
        { id: '2', color1: '#8ECDF1', color2: '#7AC4F9', lowerLimit: 50, limit: 100 },
        { id: '3', color1: '#5EA7DC', color2: '#4D8AB6', lowerLimit: 100, limit: 150 },
        { id: '4', color1: '#1D75B5', color2: '#164986', lowerLimit: 150, limit: 200 },
        { id: '5', color1: '#174565', color2: '#082B44', lowerLimit: 200, limit: 1000 },
      ];

      const precipCircleGradient = defGroup.append('defs').attr('class', 'precipitation-circle-gradient');

      precipCircleGradient
        .selectAll('linearGradient')
        .data(precipColor)
        .join('linearGradient')
        .attr('id', (d) => `precip-range-${d.id}`)
        .attr('x1', '0%')
        .attr('x2', '0%')
        .attr('y1', '0%')
        .attr('y2', '100%')
        .call((linearGradient) =>
          linearGradient
            .append('stop')
            .attr('offset', '0%')
            .style('stop-color', (d) => d.color1)
        )
        .call((linearGradient) =>
          linearGradient
            .append('stop')
            .attr('offset', '100%')
            .style('stop-color', (d) => d.color2)
        );
    },
    drawPrecipitationCircles() {
      const circle = this.svg.append('g').attr('class', 'data-precipitation-circle');

      circle
        .append('circle')
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('r', this.chartDim.outerRadius - 50)
        .attr('fill', 'none')
        .attr('stroke', '#343768')
        .attr('stroke-opacity', '0.25')
        .attr('stroke-width', 0.5)
        .attr('stroke-dasharray', 4);

      // get max and min prep
      const precipMax = d3.max(this.data, (d) => +d.annual_prep);
      const precipMin = d3.min(this.data, (d) => +d.annual_prep);

      circle
        .selectAll('circle.precipitation')
        .data(this.data)
        .join('circle')
        .attr('class', 'precipitation')
        .attr('cx', (d) => d3.pointRadial(this.xScale(d.year), this.chartDim.outerRadius - 50)[0])
        .attr('cy', (d) => d3.pointRadial(this.xScale(d.year), this.chartDim.outerRadius - 50)[1])
        .attr('r', (d) => this.rScale(d.annual_prep))
        .attr('fill', (d) => {
          if (+d.annual_prep < 50) {
            return 'url(#precip-range-1)';
          } else if (+d.annual_prep < 100) {
            return 'url(#precip-range-2)';
          } else if (+d.annual_prep < 150) {
            return 'url(#precip-range-3)';
          } else if (+d.annual_prep < 200) {
            return 'url(#precip-range-4)';
          } else if (+d.annual_prep > 200) {
            return 'url(#precip-range-5)';
          } else {
            return '#343768';
          }
        });

      circle
        .selectAll('circle.precipitation-outline')
        .data(this.data)
        .join('circle')
        .attr('class', 'precipitation-outline')
        .attr('cx', (d) => d3.pointRadial(this.xScale(d.year), this.chartDim.outerRadius - 50)[0])
        .attr('cy', (d) => d3.pointRadial(this.xScale(d.year), this.chartDim.outerRadius - 50)[1])
        .attr('r', (d) => this.rScale(+d.annual_prep + 80))
        .attr('stroke', (d) => (+d.annual_prep === +precipMax || +d.annual_prep === +precipMin ? '#343768' : '#f5f9fe'))
        .attr('fill', '#f5f9fe')
        .attr('fill-opacity', 0)
        .on('mouseover', (event, d) => {
          this.showData = 'precipitation';
          this.selectedItem = d;
          let x = event.offsetX;
          let y = event.offsetY;
          d3.select(`.tooltip.${this.city}`)
            .style('opacity', 1)
            .transition('outlol')
            .duration(0)
            .style('opacity', 1)
            .style('top', `${y + 10}px`)
            .style('left', `${x}px`);
        })
        .on('mouseout', () => {
          d3.select(`.tooltip.${this.city}`).transition('outlol').duration(1500).style('opacity', 0);
        });

      const labelPath = d3
        .arc()
        .innerRadius(this.chartDim.outerRadius - 25)
        .outerRadius(this.chartDim.outerRadius - 30)
        .startAngle(-Math.PI)
        .endAngle(Math.PI);

      circle.append('path').attr('d', labelPath).attr('id', 'precipitationlabel').attr('fill', 'none');

      circle
        .append('text')
        .attr('class', 'precipitationlabel--text')
        .append('textPath')
        .attr('startOffset', '25%')
        .style('text-anchor', 'middle')
        .attr('xlink:href', '#precipitationlabel')
        .attr('class', 'precipitationlabel')
        .text(this.$t('btn.precip.annual'));
    },
    drawTemperatureBand() {
      const x = d3
        .scaleBand()
        .domain(this.data.map((d) => d.year))
        .range([0, Math.PI * 2]);

      const arcTemp = d3
        .arc()
        .innerRadius(this.chartDim.outerRadius * 0.48 - 2)
        .outerRadius(this.chartDim.outerRadius * 0.48 + 5)
        .startAngle((d) => x(d.year))
        .endAngle((d) => x(d.year) + x.bandwidth())
        .padAngle(0.0025);

      const tempBand = this.svg.append('g').attr('class', 'data-temperature-band');

      const labelPath = d3
        .arc()
        .innerRadius(this.chartDim.outerRadius * 0.5 + 5)
        .outerRadius(this.chartDim.outerRadius * 0.5 + 6)
        .startAngle(-Math.PI)
        .endAngle(Math.PI);

      tempBand.append('path').attr('d', labelPath).attr('id', 'temperaturelabel').attr('fill', 'none');

      tempBand
        .append('text')
        .attr('class', 'temperaturelabel--text')
        .append('textPath')
        .attr('startOffset', '25%')
        .style('text-anchor', 'middle')
        .attr('xlink:href', '#temperaturelabel')
        .attr('class', 'temperaturelabel')
        .text(this.$t('btn.temp.annual'));

      tempBand
        .selectAll('path.temperature')
        .data(this.data)
        .join('path')
        .attr('class', 'temperature')
        .attr('d', arcTemp)
        .attr('fill', (d) => this.tempScale(d[this.temperature]))
        .attr('opacity', 1)
        .on('mouseover', (event, d) => {
          this.showData = 'temperature';
          this.selectedItem = d;
          let x = event.offsetX;
          let y = event.offsetY;
          d3.select(`.tooltip.${this.city}`)
            .style('opacity', 1)
            .transition('outlol')
            .duration(0)
            .style('opacity', 1)
            .style('top', `${y + 10}px`)
            .style('left', `${x}px`);
        })
        .on('mouseout', () => {
          d3.select(`.tooltip.${this.city}`).transition('outlol').duration(1500).style('opacity', 0);
        });
    },
    drawHeatIndexLines() {
      const hiLines = this.svg.append('g').attr('class', 'data-heatindex-lines');

      const hiLinesXScale = d3
        .scaleBand()
        .domain(this.data.map((d) => d.year))
        .range([0, Math.PI * 2]);

      this.hiYAxis = hiLines
        .append('g')
        .attr('class', 'hi-yAxis')
        .call((g) =>
          g
            .attr('text-anchor', 'middle')
            .attr('font-family', 'sans-serif')
            .call((g) =>
              g
                .selectAll('g')
                .data(this.hiLinesYScale.ticks(4).reverse())
                .join('g')
                .attr('fill', 'none')
                .call((g) =>
                  g
                    .append('circle')
                    .attr('stroke', '#343768')
                    .attr('stroke-opacity', '0.25')
                    .attr('stroke-width', 0.5)
                    .attr('stroke-dasharray', 4)
                    .attr('r', this.hiLinesYScale)
                )
                .call((g) =>
                  g
                    .append('text')
                    .attr('y', (d) => -this.hiLinesYScale(d))
                    .attr('dy', '0.35em')
                    .attr('stroke', '#f5f9fe')
                    .attr('stroke-width', 2)
                    .text((x, i) => `${x.toFixed(0)}${i ? '°C' : '°C'}`)
                    .clone(true)
                    .attr('y', (d) => this.hiLinesYScale(d))
                    .selectAll(function () {
                      return [this, this.previousSibling];
                    })
                    .clone(true)
                    .attr('fill', 'currentColor')
                    .attr('stroke', 'none')
                )
            )
        );

      // draw the labels for heat index
      const labelPath = d3
        .arc()
        .innerRadius(this.chartDim.outerRadius * 0.8)
        .outerRadius(this.chartDim.outerRadius * 0.8)
        .startAngle(-Math.PI)
        .endAngle(Math.PI);

      hiLines.append('path').attr('d', labelPath).attr('id', 'heatindexlabel').attr('fill', 'none');

      hiLines
        .append('text')
        .attr('class', 'heatindexlabel--text')
        .append('textPath')
        .attr('startOffset', '25%')
        .style('text-anchor', 'middle')
        .attr('xlink:href', '#heatindexlabel')
        .attr('class', 'heatindexlabel')
        .text(this.$t('btn.hi.annual'));

      hiLines
        .append('g')
        .attr('class', 'hi-line-marker')
        .append('defs')
        .append('marker')
        .attr('id', 'taper')
        .attr('refX', 1)
        .attr('refY', 4)
        .attr('markerWidth', 4)
        .attr('markerHeight', 4)
        .attr('orient', -90)
        .append('polygon')
        .attr('fill', '#f5f9fe')
        .attr('points', '0 0, 0 4, 2 4, 2 0, 1 4, 0, 0');

      hiLines
        .append('g')
        .attr('class', 'hi-line-marker-2')
        .append('defs')
        .append('marker')
        .attr('id', 'taper-bottom')
        .attr('refX', 1)
        .attr('refY', 4)
        .attr('markerWidth', 4)
        .attr('markerHeight', 4)
        .attr('orient', 90)
        .append('polygon')
        .attr('fill', '#f5f9fe')
        .attr('points', '0 0, 0 4, 2 4, 2 0, 1 4, 0, 0');

      hiLines
        .append('g')
        .attr('class', 'hi-lines')
        .selectAll('g.hi-line')
        .data(this.data)
        .join('g')
        .attr('class', 'hi-line')
        .attr(
          'transform',
          (d) => `
          rotate(${((hiLinesXScale(d.year) + hiLinesXScale.bandwidth() / 2) * 180) / Math.PI - 90})
        `
        )
        .call((g) =>
          g
            .append('line')
            .attr('class', 'hi-line')
            .attr('x2', (d) => this.hiLinesYScale(d.annual_hi))
            .attr('x1', this.chartDim.outerRadius * 0.76 - 70)
            .attr('stroke', (d) => this.hiScale(d.annual_hi))
            .attr('marker-end', 'url(#taper)')
            .attr('marker-start', 'url(#taper-bottom)')
            .attr('stroke-width', 5)
        )
        .on('mouseover', (event, d) => {
          this.selectedItem = d;
          this.showData = 'heatindex';
          let x = event.offsetX;
          let y = event.offsetY;
          d3.select(`.tooltip.${this.city}`)
            .style('opacity', 1)
            .transition('outlol')
            .duration(0)
            .style('opacity', 1)
            .style('top', `${y + 10}px`)
            .style('left', `${x}px`);
        })
        .on('mouseout', () => {
          d3.select(`.tooltip.${this.city}`).transition('outlol').duration(1500).style('opacity', 0);
        });
    },
    drawDangerousDaysFlames() {
      const flame =
        'M14.69,0c-2,13.8-5.63,39.46-5.63,39.46-2.65,20.6,5.63,34.39,5.63,34.39l5.22-36.92C23.42,13.27,14.69,0,14.69,0Z';

      const ddaysFlame = this.svg.append('g').attr('class', 'data-dangerdays-flames');

      const ddaysFlameXScale = d3
        .scaleBand()
        .domain(this.data.map((d) => d.year))
        .range([0, Math.PI * 2]);

      const ddaysYScale = d3
        .scaleLinear()
        .domain([0, 365])
        .range([this.chartDim.outerRadius * 0.375 - 60, this.chartDim.outerRadius * 0.375]);

      ddaysFlame.append('g').call((g) =>
        g.attr('text-anchor', 'middle').call((g) =>
          g
            .selectAll('g')
            .data([0, 100, 200, 365])
            .join('g')
            .attr('fill', 'none')
            .call((g) =>
              g
                .append('circle')
                .attr('stroke', '#343768')
                .attr('stroke-opacity', '0.25')
                .attr('stroke-width', 0.5)
                .attr('stroke-dasharray', 4)
                .attr('r', ddaysYScale)
            )
            .call((g) =>
              g
                .append('text')
                .attr('y', (d) => -ddaysYScale(d))
                .attr('dy', '0.35em')
                .attr('stroke', '#f5f9fe')
                .attr('stroke-width', 2)
                .text((x, i) => `${x.toFixed(0)}${i ? '' : ''}`)
                .clone(true)
                .attr('y', (d) => ddaysYScale(d))
                .selectAll(function () {
                  return [this, this.previousSibling];
                })
                .clone(true)
                .attr('fill', 'currentColor')
                .attr('stroke', 'none')
            )
        )
      );

      const labelPath = d3
        .arc()
        .innerRadius(this.chartDim.outerRadius * 0.42)
        .outerRadius(this.chartDim.outerRadius * 0.42)
        .startAngle(-Math.PI)
        .endAngle(Math.PI);

      ddaysFlame.append('path').attr('d', labelPath).attr('id', 'dangerdayslabel').attr('fill', 'none');

      ddaysFlame
        .append('text')
        .attr('class', 'ddayslabel--text')
        .append('textPath')
        .attr('startOffset', '25%')
        .style('text-anchor', 'middle')
        .attr('xlink:href', '#dangerdayslabel')
        .attr('class', 'ddayslabel')
        .text(this.$t('btn.days.dangerous_days'));

      const ddaysMax = d3.max(this.data, (d) => +d.dangerous_days);

      ddaysFlame
        .append('g')
        .attr('class', 'ddays-flames')
        .selectAll('g.ddays-flame')
        .data(this.data)
        .join('g')
        .attr('class', 'ddays-flame')
        .attr(
          'transform',
          (d, i) => `
          translate(${d3.pointRadial(ddaysFlameXScale(d.year), this.chartDim.outerRadius * 0.375)[0]}, ${
            d3.pointRadial(ddaysFlameXScale(d.year), this.chartDim.outerRadius * 0.375)[1]
          })
          scale(0.8)
          rotate(${(i * 360) / 42})
          `
        )
        .append('path')
        .attr('class', 'ddays-flame')
        .attr('d', flame)
        .attr(
          'stroke',
          (d) => `${+d.dangerous_days === +ddaysMax ? '#343768' : +d.dangerous_days > 100 ? '#C87944' : '#DEC363'}`
        )
        .attr('fill', (d) => `url(#${this.city}-ddays-${d.year})`)
        .on('mouseover', (event, d) => {
          this.selectedItem = d;
          this.showData = 'ddays';
          let x = event.offsetX;
          let y = event.offsetY;
          d3.select(`.tooltip.${this.city}`)
            .style('opacity', 1)
            .transition('outlol')
            .duration(0)
            .style('opacity', 1)
            .style('top', `${y + 10}px`)
            .style('left', `${x}px`);
        })
        .on('mouseout', () => {
          d3.select(`.tooltip.${this.city}`).transition('outlol').delay(100).duration(1500).style('opacity', 0);
        });
    },
    initScales() {
      this.xScale = d3
        .scaleLinear()
        .domain([1978.5, 2021.5])
        .range([0, Math.PI * 2]);

      this.rScale = d3.scaleLinear().domain([0, 288]).range([0, 22.5]);

      // 6.2 - 44.1
      this.tempScale = d3
        .scaleLinear()
        .domain([
          6.2, 7.6037037037037, 9.00740740740741, 10.4111111111111, 11.8148148148148, 13.2185185185185,
          14.6222222222222, 16.0259259259259, 17.4296296296296, 18.8333333333333, 20.237037037037, 21.6407407407407,
          23.0444444444444, 24.4481481481482, 25.8518518518519, 27.2555555555556, 28.6592592592593, 30.062962962963,
          31.4666666666667, 32.8703703703704, 34.2740740740741, 35.6777777777778, 37.0814814814815, 38.4851851851852,
          39.8888888888889, 41.2925925925926, 42.6962962962963, 44.1,
        ])
        .range([
          '#1386AB',
          '#2099C0',
          '#2CA6CD',
          '#42B9DF',
          '#57C6E9',
          '#57CFE9',
          '#6FD6ED',
          '#85DFE5',
          '#A0E7EB',
          '#BAEBEE',
          '#FFEAC2',
          '#FFE3C2',
          '#FFDCC2',
          '#FDD7B3',
          '#FDCC9F',
          '#FFBF85',
          '#FFB067',
          '#FEA24C',
          '#FC8F2B',
          '#F87328',
          '#F3521E',
          '#E33921',
          '#CD1717',
          '#A30C0C',
          '#850808',
          '#600101',
          '#3D0606',
          '#0B0000',
        ])
        .interpolate(d3.interpolateRgb.gamma(2.2));

      // 5 - 48.7
      this.hiScale = d3
        .scaleLinear()
        .domain([
          5, 6.61851851851852, 8.23703703703704, 9.85555555555556, 11.4740740740741, 13.0925925925926, 14.7111111111111,
          16.3296296296296, 17.9481481481481, 19.5666666666667, 21.1851851851852, 22.8037037037037, 24.4222222222222,
          26.0407407407407, 27.6592592592593, 29.2777777777778, 30.8962962962963, 32.5148148148148, 34.1333333333334,
          35.7518518518519, 37.3703703703704, 38.9888888888889, 40.6074074074074, 42.225925925926, 43.8444444444445,
          45.462962962963, 47.0814814814815, 48.7,
        ])
        .range([
          '#1386AB',
          '#2099C0',
          '#2CA6CD',
          '#42B9DF',
          '#57C6E9',
          '#57CFE9',
          '#6FD6ED',
          '#85DFE5',
          '#A0E7EB',
          '#BAEBEE',
          '#FFEAC2',
          '#FFE3C2',
          '#FFDCC2',
          '#FDD7B3',
          '#FDCC9F',
          '#FFBF85',
          '#FFB067',
          '#FEA24C',
          '#FC8F2B',
          '#F87328',
          '#F3521E',
          '#E33921',
          '#CD1717',
          '#A30C0C',
          '#850808',
          '#600101',
          '#3D0606',
          '#0B0000',
        ])
        .interpolate(d3.interpolateRgb.gamma(2.2));

      this.createYearBand();
    },
    createYearBand() {
      const x = d3
        .scaleBand()
        .domain(this.data.map((d) => d.year))
        .range([0, Math.PI * 2]);

      this.svg
        .append('g')
        .attr('class', 'year-axis')
        .attr('text-anchor', 'middle')
        .call((g) =>
          g
            .selectAll('g')
            .data(this.data)
            .join('g')
            .attr(
              'transform',
              (d) => `
              rotate(${((x(d.year) + x.bandwidth() / 2) * 180) / Math.PI - 90})
              translate(${this.chartDim.innerRadius}, 0)
            `
            )
            .call((g) =>
              g
                .append('line')
                .attr('x2', this.chartDim.outerRadius + this.chartDim.innerRadius)
                .attr('x1', 0)
                .attr('stroke-opacity', 0.1)
                .attr('stroke', '#cccccc')
            )
            .call((g) =>
              g
                .append('text')
                .attr('class', 'year-labels')
                .attr('transform', (d) =>
                  (x(d.year) + x.bandwidth() / 4 + Math.PI / 2) % (2 * Math.PI) < Math.PI
                    ? `rotate(90)translate(0,-${this.chartDim.outerRadius * 0.75})`
                    : `rotate(-90)translate(0, ${this.chartDim.outerRadius * 0.75 + 10})`
                )
                .text((d) => d.year)
            )
        );
    },
    drawArabicLabels() {      
      const labels = this.svg.append('g').attr('class', 'arabic-labels');

      // precipitation
      labels
        .append('g')
        .attr('class', 'precipitation-ar')
        .attr('transform', `translate(-${this.chartDim.outerRadius / 2 - 35}, -${this.chartDim.outerRadius - 15})`)
        .selectAll('path')
        .data(this.labelsAr.precipitation)
        .enter()
        .append('path')
        .attr('d', d => d.d)
        .attr('data-id', d => d.data) 

      labels
        .selectAll('path[data-id="btn.precip.annual"]')
        .attr('transform', 'translate(-200, -2.64)')
                 

      // heat index
      labels
        .append('g')
        .attr('class', 'heatindex-ar')
        .attr('transform', `translate(-${this.chartDim.outerRadius * 0.8}, -${this.chartDim.outerRadius * 0.8 + 15})`)
        .selectAll('path')
        .data(this.labelsAr.heatindex)
        .enter()
        .append('path')
        .attr('d', d => d.d)
        .attr('data-id', d => d.data) 

      labels
        .selectAll('path[data-id="btn.hi.summer"], path[data-id="btn.hi.winter"]')
        .attr('transform', 'translate(170, 5)')            
        
      // temperature
      labels
        .append('g')
        .attr('class', 'temperature-ar')
        .attr('transform', `translate(-${this.chartDim.outerRadius * 0.5 + 12}, -${this.chartDim.outerRadius * 0.5 + 25})`)
        .selectAll('path')
        .data(this.labelsAr.temperature)
        .enter()
        .append('path')
        .attr('d', d => d.d)
        .attr('data-id', d => d.data)   
        
      labels
        .selectAll('path[data-id="btn.temp.summer"], path[data-id="btn.temp.winter"]')
        .attr('transform', 'translate(70, 5)')        

      // dangerous days
      labels
        .append('g')
        .attr('class', 'days-ar')
        .attr('transform', `translate(-${this.chartDim.outerRadius * 0.44}, -${this.chartDim.outerRadius * 0.46})`)
        .selectAll('path')
        .data(this.labelsAr.days)
        .enter()
        .append('path')
        .attr('d', d => d.d)
        .attr('data-id', d => d.data)

      labels
        .selectAll('path[data-id="btn.days.100f_days"]')
        .attr('transform', 'translate(32, 8)')        
    },    
    responsivefy(svg) {
      const container = d3.select(svg.node().parentNode);
      const width = parseInt(svg.style('width'), 10);
      const height = parseInt(svg.style('height'), 10);
      const aspect = width / height;

      svg.attr('viewBox', `0 0 ${width} ${height}`).attr('preserveAspectRatio', 'xMinYMid').call(this.resize, aspect);

      d3.select(window).on(`resize.${container.attr('id')}`, () => {
        this.resize(svg, aspect);
      });
    },
    resize(svg, aspect) {
      const container = d3.select(svg.node().parentNode);
      const w = parseInt(container.style('width'));

      svg.attr('width', w);
      svg.attr('height', Math.round(w / aspect));
    },
  },
};
</script>

